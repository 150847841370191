    <template>
      <div class="todo-container">
        <h1 class="todo-list-title">Todo-Liste</h1>
        <!-- Anzeige der To-Do-Liste -->
        <div class="category-section" v-for="(todos, category) in categorizedTodos" :key="category">
          <h2 class="category-title">{{ category }}</h2>
          <ul class="todo-list">
            <li v-for="todo in todos" :key="todo.id" :class="{ 'todo-item': true, 'completed': todo.completed }">
              <span class="todo-title">{{ todo.title }}</span>
              <span :class="{'countdown': true, 'overdue': isOverdue(todo.dueDate, todo.dueTime)}">
                Fällig in: {{ calculateCountdown(todo.dueDate, todo.dueTime) }}
              </span>
              <div class="todo-buttons">
                  <button @click="toggleCompleted(todo)" class="mark-completed-button">
                    {{ todo.completed ? 'Nicht erledigt' : 'Erledigt' }}
                  </button>
                  <button @click="editTodo(todo)" class="edit-todo-button">Bearbeiten</button>
                  <button @click="deleteTodo(todo.id)" class="delete-todo-button">Löschen</button>
                </div>
            </li>
          </ul>
        </div>
        <!-- Formular zum Hinzufügen neuer To-Dos -->
        <button class="formtoggle-button" @click="toggleFormVisibility">+</button>
                <!-- Form for adding new To-Dos (conditionally rendered based on formVisibility) -->
                    <form v-if="formVisibility" class="todo-form" @submit.prevent="addTodo">
                      <input class="todo-input" v-model="newTodoTitle" placeholder="Neues Todo hinzufügen" />
                      <input type="date" v-model="newTodoDueDate" class="todo-date-input" />
                      <input type="time" v-model="newTodoDueTime" class="todo-time-input" />
                      <input type="text" v-model="newTodoCategory" class="todo-category-input" placeholder="Kategorie eingeben" />
                      <button class="todo-button" type="submit">Hinzufügen</button>
                    </form>

                <!-- TodoForm für das Bearbeiten von To-Dos -->
                <ToDoForm
                  v-if="selectedTodo"
                  :todo="selectedTodo"
                  :editMode="isEditMode"
                  @save="handleSave"
                />

      </div>
    </template>
    <!-- Importieren -->
    <script>
    import { v4 as uuidv4 } from 'uuid';
    import ToDoForm from './ToDoForm.vue';
    import axios from 'axios';

    /**
     * Hauptkomponente für die Verwaltung der To-Do-Liste.
     * Ermöglicht das Hinzufügen, Bearbeiten, Löschen und Anzeigen von To-Dos.
     * To-Dos werden nach Kategorien organisiert und können nach Fälligkeitsdatum sortiert werden.
     */

    export default {
      components: {
        ToDoForm
      },
      data() {
        return {
        selectedTodo: null,
        isEditMode: false,
          newTodoTitle: '',
          newTodoDueDate: '',
          newTodoDueTime: '',
          newTodoCategory: '',
          formVisibility: false,
          todos: []

        };
      },
      computed: {
        categorizedTodos() {
          return this.todos.reduce((acc, todo) => {
            const category = todo.category || 'Sonstige';
            if (!acc[category]) {
              acc[category] = [];
            }
            acc[category].push(todo);
            return acc;
          }, {});
        }
      },

      //Methoden der Funktionen, wie hinzufügen, speichern, löschen etc
      methods:
      {
          toggleFormVisibility() {
            this.formVisibility = !this.formVisibility; // Toggle the visibility on button click
          },

      //Methode Hinzufügen neues Todo
        async addTodo() {
            if (!this.newTodoTitle.trim()) {
                alert('Todo-Titel darf nicht leer sein');
                return;
            }

            const newTodo = {
                title: this.newTodoTitle,
                dueDate: this.newTodoDueDate || null, // Setzen auf null, wenn kein Datum angegeben ist
                dueTime: this.newTodoDueTime || null, // Setzen auf null, wenn keine Zeit angegeben ist
                category: this.newTodoCategory.trim() || 'Sonstige',
                completed: false
            };

            try {
                const response = await axios.post('https://charlo.site/api/todos', newTodo, {
                    headers: {
                        // Add any custom headers here if needed
                        // 'Content-Type': 'application/json',
                        // 'Authorization': 'Bearer YourAuthToken'
                    }
                });
                this.todos.push(response.data);
                this.resetForm();
                this.formVisibility = false;
            } catch (error) {
                this.handleRequestError(error);
            }
        },

        // Generic method to handle request errors
        handleRequestError(error) {
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                console.error('Error response data:', error.response.data);
                console.error('Error response status:', error.response.status);
                console.error('Error response headers:', error.response.headers);
            } else if (error.request) {
                // The request was made but no response was received
                console.error('Error request:', error.request);
            } else {
                // Something happened in setting up the request that triggered an Error
                console.error('Error message:', error.message);
            }
            alert('Ein Fehler ist aufgetreten: ' + error.message);
        },

              resetForm() {
                this.newTodoTitle = '';
                this.newTodoDueDate = '';
                this.newTodoDueTime = '';
                this.newTodoCategory = '';
              },
      //Methode Bearbeiten vorhandenes Todo
        editTodo(todo) {
              console.log('Bearbeiten wird aufgerufen', todo); // Debugging
              this.selectedTodo = { ...todo };
              this.isEditMode = true;
            },
      //Methode Abrufen gespeicherte Todos
        async fetchTodos() {
                    try {
                        const response = await axios.get('https://charlo.site/api/todos');
                        this.todos = response.data;
                    } catch (error) {
                        console.error('Fehler beim Abrufen der To-Dos:', error);
                    }
                },
      //Methode Speichern eienes Todo
        handleSave(updatedTodo) {
              if (this.isEditMode) {
                const index = this.todos.findIndex(t => t.id === updatedTodo.id);
                if (index !== -1) {
                  this.todos.splice(index, 1, updatedTodo);
                }
              } else {
                this.todos.push(updatedTodo);
              }
              this.selectedTodo = null;
              this.isEditMode = false;
            },
      //Methode Löschen eines Todo -->
        async deleteTodo(id) {
                try {
                    await axios.delete(`https://charlo.site/api/todos/${id}`);
                    this.todos = this.todos.filter(todo => todo.id !== id); // Entfernen das To-Do aus dem lokalen Zustand
                } catch (error) {
                    console.error('Fehler beim Löschen des To-Dos', error);
                }
            },
      //Methode Überprüfung ob Erledigt Todo
        toggleCompleted(todo) {
          todo.completed = !todo.completed;
        },
      //Methode Fälligkeitsdatum berechnen
        calculateCountdown(dueDate, dueTime) {
          if (!dueDate || !dueTime) return 'Kein Fälligkeitsdatum';
          const dueDateTime = new Date(`${dueDate}T${dueTime}`).getTime();
          const now = new Date().getTime();
          const diff = dueDateTime - now;
          if (diff <= 0) return 'Überfällig!';
          const days = Math.floor(diff / (1000 * 60 * 60 * 24));
          const hours = Math.floor((diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
          return `${days} Tage, ${hours} Stunden`;
        },
      //Methode Ermitteln ob Fälligkeit überschritten
        isOverdue(dueDate, dueTime) {
                if (!dueDate || !dueTime) {
                    return false; // Nicht überfällig, wenn Datum oder Zeit fehlen
                }
                const dueDateTime = new Date(`${dueDate}T${dueTime}`).getTime();
                const now = new Date().getTime();
                return dueDateTime < now;
        }
       },mounted() {
                 this.fetchTodos();
             }
    };
    </script>

    <style scoped>

    </style>

