import { createRouter, createWebHistory } from 'vue-router';
import Home from '../views/UserHome.vue'; // Make sure this component exists
import UserLogin from '../views/UserLogin.vue';

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/login',
    name: 'UserLogin',
    component: UserLogin,
  },
  {
    path: '/todos',
    name: 'Todos',
    component: () => import(/* webpackChunkName: "todos" */ '../components/ToDoList.vue'),
    meta: { requiresAuth: true }, // Nur zugänglich, wenn authentifiziert
  },
  {
    path: '/habits',
    name: 'Habits',
    component: () => import(/* webpackChunkName: "habits" */ '../components/HabitList.vue'),
    meta: { requiresAuth: true }, // Nur zugänglich, wenn authentifiziert
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  const isLoggedIn = localStorage.getItem('isLoggedIn');

  // If the route requires authentication and the user is not authenticated, redirect to the login page
  if (to.meta.requiresAuth && !isLoggedIn) {
    next('/login');
  } else {
    next();
  }
});

export default router;
