<template>
  <div>
    <h2 class="todo-form-title">Add/Edit To-Do</h2>
<!-- Formular zum Hinzufügen/Bearbeiten eines ToDos -->
    <form @submit.prevent="saveTodo">
      <input v-model="localTodo.title" placeholder="To-Do Titel" />
      <button class="update-button" type="submit">{{ editMode ? 'Update' : 'Add' }}</button>
    </form>
  </div>
</template>

<script>

/**
 * Die ToDoForm-Komponente wird zum Hinzufügen neuer oder zum Bearbeiten bestehender To-Dos verwendet.
 * Sie nimmt ein To-Do-Objekt als Prop entgegen und arbeitet mit einer lokalen Kopie, um direkte
 * Mutationen des Props zu vermeiden. Änderungen werden über ein 'save'-Event an die Elternkomponente
 * kommuniziert.
 */

export default {
  props: {
    todo: {
      type: Object,
      required: true
    },
    editMode: Boolean
  },
  data() {
    return {
      localTodo: JSON.parse(JSON.stringify(this.todo)) // Erstellen einer tiefen Kopie
    };
  },
  methods: {
    saveTodo() {
      this.$emit('save', this.localTodo); // Senden der Kopie statt der Prop
    }
  },
  watch: {
    todo(newVal) {
      this.localTodo = JSON.parse(JSON.stringify(newVal)); // Aktualisieren der Kopie bei Änderungen
    }
  }
};
</script>

<style scoped>
/* Stil für das Formular */
form {
  margin-top: 20px;
}

input {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
}

button {
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
}
button:hover {
  background-color: #0056b3;
}
.todo-form-title {
font-family: 'Roboto', sans-serif;
}
.update-button {
background-color: #FFA500;
}
.update-button:hover {
background-color: #FF8C00;
}
</style>


