<template>
  <div class="habit-container">
    <h1 class="habit-list-title">Habit List</h1>

    <!-- Input field for selectable date -->
    <label for="vitamineDate" class="habit-label"><b>Vitamine Date:</b></label>
    <input type="date" v-model="vitamineDate" id="vitamineDate" class="Vitamine-date-input" />

    <!-- Display countdown -->
    <div v-if="vitamineDate" :class="countdownClass">
      <p>Countdown: {{ stopwatch }}</p>
    </div>

    <!-- Button to trigger saveVitamineDate -->
    <button @click="saveVitamineDate">Save Vitamine Date</button>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      vitamineDate: '',
      stopwatch: 'No selected date',
      countdownIntervalId: null,
    };
  },
  computed: {
    countdownClass() {
      // Determine the class based on elapsed time
      if (this.elapsedTime > 4 * 24 * 60 * 60 * 1000) {
        return 'countdown-green'; // If more than 4 days have passed, make it green
      } else if (this.elapsedTime < 0) {
        return 'countdown-red'; // If the selected date is in the future, make it red
      } else {
        return ''; // Default class
      }
    },
    elapsedTime() {
      // Calculate the elapsed time
      const selectedDateTime = new Date(this.vitamineDate).getTime();
      const now = new Date().getTime();
      return now - selectedDateTime;
    },
  },
  methods: {
    startCountdown(selectedDate) {
      this.stopwatch = 'No selected date';
      clearInterval(this.countdownIntervalId);

      if (selectedDate) {
        this.updateCountdown(selectedDate);
        this.countdownIntervalId = setInterval(() => {
          this.updateCountdown(selectedDate);
        }, 1000);
      }
    },
    updateCountdown(selectedDate) {
      const selectedDateTime = new Date(selectedDate).getTime();
      const now = new Date().getTime();
      let elapsedTime = now - selectedDateTime;

      if (elapsedTime <= 0) {
        elapsedTime = Math.abs(elapsedTime); // Use absolute value for elapsed time after the selected date
      }

      const days = Math.floor(elapsedTime / (24 * 60 * 60 * 1000));
      const hours = Math.floor((elapsedTime % (24 * 60 * 60 * 1000)) / (60 * 60 * 1000));
      const minutes = Math.floor((elapsedTime % (60 * 60 * 1000)) / (60 * 1000));
      const seconds = Math.floor((elapsedTime % (60 * 1000)) / 1000);

      this.stopwatch = `${days} days, ${hours} hours, ${minutes} minutes, ${seconds} seconds`;
    },
    saveVitamineDate() {
      if (this.vitamineDate) {
        axios.get('https://charlo.site/api/habits')
          .then(response => {
            const habits = response.data;

            if (habits.length > 0) {
              const existingHabit = habits[0];
              existingHabit.vitamineDate = this.vitamineDate;
              return axios.post('https://charlo.site/api/habits', existingHabit);
            } else {
              const newHabit = { vitamineDate: this.vitamineDate };
              return axios.post('https://charlo.site/api/habits', newHabit);
            }
          })
          .then(response => {
            console.log('Habit created/updated successfully:', response.data);
            this.vitamineDate = response.data.vitamineDate;
            this.startCountdown(response.data.vitamineDate);
          })
          .catch(error => {
            console.error('Error creating/updating Habit:', error);
          });
      } else {
        console.warn('Vitamine Date is empty. Not saving.');
      }
    },
    fetchData() {
      axios.get('https://charlo.site/api/habits')
        .then(response => {
          const habits = response.data;
          if (habits.length > 0) {
            this.vitamineDate = habits[0].vitamineDate;
            this.startCountdown(habits[0].vitamineDate);
          }
        })
        .catch(error => {
          console.error('Error fetching Habits:', error);
        });
    },
  },
  mounted() {
    this.fetchData();
  },
  beforeUnmount() {
    clearInterval(this.countdownIntervalId);
  },
};
</script>

<style scoped>
.countdown-green {
  color: green;
}

.countdown-red {
  color: red;
}
</style>
