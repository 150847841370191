        import { createApp } from 'vue';
        import App from './App.vue';
        import axios from 'axios';
        import './assets/fonts/fonts.css';
        import './assets/globalstil.css';
        import router from './router/router';

        // Create an Axios instance with a base URL
        const axiosInstance = axios.create({
          baseURL: 'https://charlo.site/api', // Backend URL
          // Additional configu rations can be added here
        });

        // Add a request interceptor
        axiosInstance.interceptors.request.use(config => {
          // Assuming your token is stored in localStorage
          const token = localStorage.getItem('token');
          if (token) {
            config.headers.Authorization = `Bearer ${token}`;
          }
          return config;
        }, error => {
          // Handle request error
          return Promise.reject(error);
        });

        // Create Vue application
        const app = createApp(App);

        // Make axios instance available throughout your app
        app.config.globalProperties.$axios = axiosInstance;

        app.use(router);

        // Mount Vue application
        app.mount('#app');




