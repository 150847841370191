<template>
  <div class="user-home">
    <h1>Willkommen auf der Startseite</h1>
    <!-- weitere Inhalte oder Vue-Komponenten einfügen -->
  </div>
</template>

<script>
export default {
  name: 'UserHome',
  // Daten, Methoden, computed properties, etc. hinzufügen
};
</script>

<style scoped>
.user-home {
  text-align: center;
  margin-top: 20px;
  /* weitere zusätzliche Stile, um das Design anzupassen */
}
</style>
