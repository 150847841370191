<template>
  <div id="app">
    <!-- Zeigt die ToDoList-Komponente nur an, wenn der Benutzer eingeloggt ist -->
    <div v-if="isLoggedIn" class="content-container">
      <!-- Button zum Wechseln zwischen Todos und Habits -->
      <button @click="toggleView" class="switch-button" :class="{ 'sticky-button': isSticky }"> {{ currentView === 'todos' ? 'Habits' : 'Todos' }} </button>

      <!-- Ansicht basierend auf dem aktuellen Zustand -->
      <div v-if="currentView === 'todos'">
        <!-- ToDoList-Komponente -->
        <ToDoList />
      </div>

      <div v-else>
        <HabitList />
      </div>
    </div>

    <!-- Andernfalls zeigt das Login-Fenster -->
    <UserLogin v-else @login-success="handleLogin" />
  </div>
</template>

<script>
import HabitList from './components/HabitList.vue';
import ToDoList from './components/ToDoList';
import UserLogin from './views/UserLogin'; // Importieren UserLogin-Komponente

export default {
  name: 'App',
  components: {
    HabitList,
    ToDoList,
    UserLogin
  },
  data() {
    return {
      isLoggedIn: false,
      currentView: 'todos', // Default-Ansicht ist Todos
      isEdit: false,
      selectedHabit: null,
      habits: [] // Annahme: Du hast die Habit-Liste hier
    };
  },
  methods: {
    handleLogin() {
      this.isLoggedIn = true;
    },
    toggleView() {
      this.currentView = this.currentView === 'todos' ? 'habits' : 'todos';
    },
    // Weitere Methoden für Habit-Verwaltung hier
  }
};
</script>

<style scoped>
.sticky-button {
  position: sticky;
  top: 0;
  background-color: red; /* Specify a background color if needed */
  z-index: 1000; /* Adjust z-index as needed */
}
.content-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  margin-top: 20px;
}

.switch-button {
  width: 120px;
  height: 60px;
  border: none;
  margin-top: 10px;
  font-family: 'Roboto-Bold', sans-serif;
  font-size: 20px;
  text-align: center;
  /* Hier kannst du weitere Stile für den Button hinzufügen */
}
</style>
