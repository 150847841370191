<template>
  <div class="global-container">
    <div class="user-login">
      <h1 class="login-title">Login</h1>
      <form @submit.prevent="handleLogin">
        <input class="benutzer" type="text" v-model="username" placeholder="Username" required>
        <input class="passwort" type="password" v-model="password" placeholder="Password" required>
        <button class="login-button" type="submit">Login</button>
      </form>
    </div>
  </div>
</template>

<script>
/**
 * UserLogin ist eine Vue-Komponente, die ein einfaches Login-Formular bereitstellt.
 * Sie ermöglicht es Benutzern, sich mit Benutzername und Passwort einzuloggen.
 * Bei erfolgreicher Authentifizierung wird ein 'login-success' Ereignis ausgelöst.
 * Die Authentifizierungslogik ist hier nur als Beispiel implementiert und sollte
 * in einer realen Anwendung durch eine serverseitige Authentifizierung ersetzt werden.
 */

export default {
  name: 'UserLogin',
  data() {
    return {
      username: '',
            password: ''
          };
        },
        created() {
          // Check if username and password are present in localStorage
          const storedUsername = localStorage.getItem('username');
          const storedPassword = localStorage.getItem('password');

          // Populate the input fields if data is present
          if (storedUsername && storedPassword) {
            this.username = storedUsername;
            this.password = storedPassword;
          }
        },
  methods: {
      handleLogin() {
        if (this.username === 'lorenz' && this.password === 'theo0908') {
          // Speichern des Login-Status - im echten Szenario durch einen Server
          localStorage.setItem('authToken', 'authenticated'); // Store a simple token or flag
          localStorage.setItem('username', this.username); // Store the username
          localStorage.setItem('password', this.password); // Store the password
          localStorage.setItem('isLoggedIn', 'true');
          this.$emit('login-success');
        } else {
          alert('Falscher Benutzername oder Passwort');
        }
      },
      handleLogout() {
          localStorage.removeItem('isLoggedIn');
          this.$router.push('/login');
        }
    }
};
</script>

<style scoped>
/* Your CSS style for the login form */
</style>
